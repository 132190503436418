$white:    #fff !default;
$gray-100: #f2f4f6 !default;
$gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #8a969c !default;
$gray-600: #666 !default;
$gray-700: #444 !default;
$gray-800: #222 !default;
$gray-900: #212121 !default;
$black:    #000 !default;

$blue:    #2196f3 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #e51c23 !default;
$orange:  #fd7e14 !default;
$yellow:  #ff9800 !default;
$green:   #4caf50 !default;
$teal:    #20c997 !default;
$cyan:    #0097a9 !default;
$gray:    $gray-200;

$sherpaTeal: #0097a9 !default;
$sherpaGrey: #748898 !default;

$sherpaPink: #c4105a !default;
$sherpaOrange: #ff9900 !default;
$sherpaDarkTeal: #008395 !default;
$sherpaLightGrey: #f6f9fb !default;
$sherpaBlack: #102434 !default;

$sherpaBlue: #14abbd !default;

$primary:       $sherpaTeal !default;
$secondary:     $sherpaGrey !default;
$success:       $primary !default;
$warning:       $sherpaOrange !default;
$info:          $sherpaDarkTeal !default;
$danger:        $sherpaPink !default;
$light:         $sherpaLightGrey !default;
$dark:          $sherpaBlack !default;

$yiq-contrasted-threshold:  170 !default;

$enable-gradients: true !default;

$body-color:    $sherpaTeal;

$font-family:    "Lato", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif !default;
$font-size-base:    16px !default;
$font-weight-base:  400 !default;

$line-height-base: 19px;

$min-width-xl: 1000px;
$min-width-lg: 800px;
$min-width-med: 720px;
$min-width-sm: 540px;

$input-btn-padding-y:       .8rem !default;
$input-btn-padding-x:       1rem !default;

$spacing-0: 0;
$spacing-1: 0.25rem;
$spacing-2: 0.5rem;
$spacing-3: 1rem;
$spacing-4: 1.5rem;
$spacing-5: 3rem;
$spacing-6: 5rem;

$input-padding-y:                1rem !default;
$input-padding-x:                0 !default;
$input-padding-y-sm:             0 !default;
$input-padding-x-sm:             0 !default;
$input-padding-y-lg:             ($font-size-base * 1.25) !default;
$input-padding-x-lg:             0 !default;
$input-bg:                       transparent !default;
$input-disabled-bg:              transparent !default;
$input-color:                    $gray-600 !default;
$input-border-color:             transparent !default;
$input-border-width:             0 !default;
$input-border-radius:            0 !default;
$input-border-radius-lg:         0 !default;
$input-border-radius-sm:         0 !default;
$input-placeholder-color:        rgba(0, 0, 0, .4) !default;
$input-group-addon-bg:           transparent !default;
$custom-control-gutter:          1.75rem !default;
$custom-control-indicator-focus-box-shadow: 0 !default;
$custom-control-indicator-size:  1.25rem !default;
$custom-control-indicator-bg:    $white !default;
$custom-checkbox-indicator-border-radius: 2px !default;
$custom-control-indicator-disabled-bg: $gray-100 !default;
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='4' fill='#2196f3'/></svg>") !default;
$custom-select-border-radius:       0 !default;
$custom-select-box-shadow:          none !default;


$nav-link-disabled-color:       $gray-500 !default;
$nav-tabs-border-color:         transparent !default;


$navbar-padding-y:                  1rem !default;
$navbar-dark-color:                 rgba($white, .75) !default;
$navbar-dark-hover-color:           $white !default;


$card-border-width:                 0 !default;
$card-border-color:                 transparent !default;


$tooltip-bg:                        $gray-700 !default;


$modal-content-border-color:        transparent !default;


$progress-height:               .375rem !default;
$progress-border-radius:        0 !default;


$close-font-size:             2.125rem !default;
$close-font-weight:           300 !default;

$btn-close-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E");
